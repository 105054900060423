.signin__wrap {
  height: 100vh;
  background: rgb(241, 245, 249);
  margin-top: -64px;
}

.paper {
  border-radius: 0px;
  box-shadow: none;
  background: none;
  height: 100%;
}

.signin__wrap__inner {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.signin__wrap__inner > div {
  width: 50%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.left__inner {
  height: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step3__content__wrap {
  padding: 20px 0;
}

@media (max-height: 680px) {
  .step3__content__wrap {
    padding: 80px 0 20px;
  }
}

@media (max-height: 570px) {
  .step3__content__wrap {
    padding: 150px 0 20px;
  }
}

@media (max-height: 460px) {
  .step3__content__wrap {
    padding: 220px 0 20px;
  }
}

.content {
  /* margin-bottom: 24px; */
}

.notice__txt {
  align-items: center;
  margin-bottom: 6px;
}

.notice__txt:last-of-type {
  margin-bottom: 0px;
}

.notice__txt > p {
  color: #999;
}

.notice__txt > a {
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.7;
}

.main__title {
  font-size: 2.6rem;
  margin-bottom: 24px;
}

.left__section {
  align-items: flex-end;
  background: white;
  overflow-y: auto;
  padding: 0rem 5rem;
}

.right__section {
  padding: 6rem 5rem;
  background: rgb(30, 41, 59);
  color: white;
  overflow: hidden;
  min-width: 550px;
}

.right__section > h2 {
  font-size: 3.2rem;
  margin-bottom: 24px;
}

.right__section > p {
  font-size: 1.4rem;
  opacity: 0.6;
  line-height: 1.62;
}

.right__section > svg {
  position: absolute;
}

.right__section > svg:first-of-type {
  right: -64px;
  top: -64px;
  opacity: 0.2;
  width: 220px;
  height: 192px;
  color: rgb(100, 116, 139);
}

.right__section > svg circle {
  color: rgb(100, 116, 139);
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

.right__section > svg:nth-of-type(2) {
  left: 0;
  top: 0;
}

.right__section > svg > g {
  color: rgb(100, 116, 139);
  opacity: 0.2;
}

.left__inner > div {
  display: flex;
  flex-direction: column;
}

.left__inner > div > p {
  opacity: 0.65;
}

.content > div {
  margin-bottom: 4px;
  min-height: 75.91px;
  display: flex;
}

.content > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content > button {
  width: 100%;
}

.signin__notice {
  margin-bottom: 12px;
  flex-direction: row !important;
}

.signin__notice a {
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none !important;
  padding: 4px 2px;
  color: var(--main-color) !important;
}

.signin__notice a:hover {
  text-decoration: underline !important;
}

.auth__type__btn > button:first-of-type {
  margin-right: 16px;
}

.sign__up__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px !important;
}

.sign__up__item > button {
  min-width: 118px;
}

.signup__btn__wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 12px 0;
}

.template__notice {
  font-size: 1rem;
  margin-bottom: 16px;
  padding: 16px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid#e6e6e6;
  line-height: 1.62;
}

.template__notice > dt {
  margin-bottom: 6px;
}

.template__notice > dd {
  opacity: 0.85;
}

.template__selector__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 360px;
}

.template__selector__wrap button {
  min-width: 46px !important;
  width: 46px !important;
  padding: 0 10px !important;
  margin-left: 12px;
}

.template__licenses__txt {
  margin: 6px 0px 16px;
  font-size: 0.8rem;
  opacity: 0.6;
}
.timeout__input {
  position: relative;
}
.timeout__input > p {
  position: absolute;
  right: 14px;
  top: 18px;
  color: var(--main-color);
  font-size: 1rem;
}
.timeout__input input {
  padding-right: 74px !important;
}
@media (max-width: 1460px) {
  .right__section > h2 {
    font-size: 2.4rem;
  }

  .right__section > p {
    font-size: 1.2rem;
  }

  .left__inner > h1 {
    font-size: 2.4rem;
  }

  .signin__notice > p {
    font-size: 0.9rem;
  }

  .signin__notice > a {
    font-size: 0.9rem;
  }

  .left__section {
    padding: 0rem 3rem;
  }

  .right__section {
    padding: 4rem 3rem;
  }
}

@media (max-width: 960px) {
  .right__section {
    display: none !important;
  }

  .left__inner {
    width: 100%;
    max-width: none;
  }

  .signin__wrap__inner {
    justify-content: center;
    background: rgb(241, 245, 249);
    align-items: center;
    overflow-y: auto;
    padding: 40px 0 20px;
    box-sizing: border-box;
  }

  .signin__wrap__inner > div {
    padding: 32px;
    width: 70%;
    background: white;
    border-radius: 16px;
    height: 100%;
    min-height: 550px;
    max-height: calc(100vh - 32px);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  }

  .auth__type__btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .auth__type__btn button {
    width: 48%;
  }
}

@media (max-width: 650px) {
  .signin__wrap__inner > div {
    max-height: none;
    width: 100%;
  }

  .left__section {
    align-items: center;
  }
}
