/* common */
@import url(./reset.css);
@import url(./common.css);
@import url(./nav.css);
@import url(./header.css);
/* // common */
/* pages */
/* @import url(./dashboard.css); */
/* // pages */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

* {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

html {
  -webkit-font-smoothing: auto;
  -webkit-text-size-adjust: 100%;
}

@font-face {
  font-family: 'NanumSquareRoundOTFR';
  src: url(../fonts/NanumSquareRoundOTFR.otf);
}

@font-face {
  font-family: 'NanumSquareRoundOTFEB';
  src: url(../fonts/NanumSquareRoundOTFEB.otf);
}

@font-face {
  font-family: 'NanumSquareRoundOTFB';
  src: url(../fonts/NanumSquareRoundOTFB.otf);
}

body {
  background: rgb(241, 245, 249);
  font-family: 'NanumSquareRoundOTFR';
  color: #333;
}

.f__medium {
  font-family: 'NanumSquareRoundOTFB';
  font-weight: 600;
}

.f__bold {
  font-family: 'NanumSquareRoundOTFEB';
  font-weight: 800;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: white;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 10px;
}

/* main layouts */
main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100%;
  z-index: 10;
  padding-top: 64px;
  position: relative;
  min-width: 0;
}

#layout {
  flex: 1 1 auto;
  display: flex;
  min-width: 0;
  min-height: 100vh;
}

main > .container {
  max-width: 1570px;
  width: 100%;
  margin: 0px auto;
  padding: 24px 12px 12px;
  box-sizing: border-box;
}

.section__grid__wrap {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  padding: 24px;
}

.section__grid__wrap.common__detail {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.section__grid__wrap.common__detail > .sub__nav {
  grid-column: span 2 / span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section__grid__wrap > section > .section__inner {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  color: rgb(17, 24, 39);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.section__grid__wrap > section > .section__inner > .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 16px; */
}

.section__grid__wrap > section > .section__inner > .top.line {
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
}

.section__grid__wrap > .section__title {
  font-size: 1.3rem;
}

.section__grid__wrap > section > .section__inner > .top > .normal__title {
  font-size: 1.1rem;
  color: rgb(107, 114, 128);
}

.section__grid__wrap > section > .section__inner {
  box-sizing: border-box;
  padding: 24px;
}

.content.service__ready,
.content.page404 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content.service__ready > img,
.content.page404 > img {
  width: 360px;
  height: 360px;
}

.content.service__ready > h3 {
  font-size: 2rem;
}

.content.page404 > img {
  margin-top: -52px;
}

.content.page404 button {
  margin-top: -52px;
}

.content.page404 dl {
  text-align: center;
}

.content.page404 dl dt {
  font-size: 1.8rem;
  margin: 22px 0;
}

.content.page404 dl dd {
  font-size: 1rem;
  opacity: 0.7;
  line-height: 1.82;
}

.content.service__ready > h3 span {
  color: var(--main-color);
}

.content .sub__nav .left {
  display: flex;
  align-items: center;
}

.content .sub__nav .left button {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  background: rgb(187, 187, 187);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.content .sub__nav .left button svg {
  fill: #fff;
}

.content .sub__nav .left button:hover {
  background: var(--main-color);
}

.content .sub__nav .left dt {
  font-size: 1.6rem;
}

.content .sub__nav .left dd {
  color: rgb(107, 114, 128);
}

/* // main layouts */

.modal__bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99998;
  display: none;
}

.gridjs-wrapper {
  min-height: 513.6px;
  overflow-x: auto;
}

table.gridjs-table {
  width: 100%;
  /* min-width: 1100px; */
}

table.gridjs-table tr:last-of-type {
  border-bottom: 1px solid #e5e7eb;
}

table.gridjs-table tr td {
  white-space: nowrap;
  overflow: hidden;
  background: none;
}

.gridjs-container {
  overflow: auto;
}

.react-datepicker__input-container > input {
  font-size: 1.1rem;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 12px 10px;
  max-width: 150px;
  pointer-events: none;
}

.react-datepicker__input-container::before {
  content: '';
  position: absolute;
  display: block;
  opacity: 0.1;
  width: calc(100% - 42px);
  height: 100%;
  left: 0;
  top: 0;
}

.react-datepicker__input-container:after {
  content: '';
  position: absolute;
  display: block;
  width: 42px;
  opacity: 0.7;
  height: 100%;
  right: 0;
  top: 0;
  border-radius: 30px;
  cursor: pointer;
  background: url(../img/icon/ic_calendar.svg) no-repeat 50% 50%;
}

#pageTitleWrap {
  grid-column: span 4 / span 4;
  box-sizing: border-box;
  padding: 24px 24px 0px;
}

#pageTitleWrap > h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

#pageTitleWrap > p {
  color: rgb(135, 140, 150);
}

#pageTitleWrap > ul {
  display: flex;
}

#pageTitleWrap > ul li {
  display: flex;
  align-items: center;
}

#pageTitleWrap > ul > li span {
  color: rgb(135, 140, 150);
  margin: 0 6px;
}

#pageTitleWrap > ul > li p,
#pageTitleWrap > ul > li a {
  text-decoration: none;
  color: rgb(135, 140, 150);
  cursor: pointer;
}

#pageTitleWrap > ul > li p:hover,
#pageTitleWrap > ul > li a:hover {
  color: var(--main-color);
}

#pageTitleWrap > ul > li p,
#pageTitleWrap > ul > li a {
  pointer-events: none;
  font-size: 1rem;
}

#pageTitleWrap > ul > li:last-of-type span {
  display: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 20px !important;
}

.ReactModal__Content {
  left: 50% !important;
  top: 50% !important;
}

div#menu- {
  z-index: 9999;
}

#loadingWrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadingWrap .bg {
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

#loadingWrap .spinner-wrap {
  position: relative;
  width: 50px;
  height: 50px;
}

#loadingWrap .spinner-wrap .spinner-border {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 1200px) {
  .main__nav ~ .modal__bg {
    display: block;
  }

  .main__nav.hide ~ .modal__bg {
    display: none;
  }
}

@media (max-width: 960px) {
  .section__grid__wrap {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* max-width : 600 ?? */
@media (max-width: 660px) {
  .section__grid__wrap {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .section__grid__wrap.common__detail > .sub__nav {
    align-items: flex-start;
    /* flex-direction: column; */
  }

  .section__grid__wrap.common__detail > .sub__nav > .left {
    margin-bottom: 18px;
  }

  .content.service__ready > img,
  .content.page404 > img {
    width: 220px;
    height: 220px;
  }

  .content.service__ready > h3 {
    font-size: 1.6rem;
  }

  .content.page404 > img {
    margin-top: -20px;
  }

  .content.page404 button {
    margin-top: -20px;
  }

  .content.page404 dl dt {
    font-size: 1.4rem;
  }

  .content.page404 dl dd {
    opacity: 0.7;
  }
}
